




















  import { Vue, Component, Prop } from 'nuxt-property-decorator'
  import { cloneDeep } from 'lodash-es'

  interface Spacing {
    top: string
    right: string
    bottom: string
    left: string
    [key: string]: string
  }

  const assignPropertiesWhenValid = (
    source: { [key: string]: string },
    properties: string[],
    target: { [key: string]: string },
    prefix = '',
  ) => {
    const propertiesWithValues = Object.keys(source).filter(
      key => properties.includes(key) && !!parseInt(source[key]),
    )
    propertiesWithValues.forEach((key) => {
      target[prefix + key] = source[key]
    })
  }

  interface Background {
    color: string
  }

  interface BackgroundStyles {
    [key: string]: string
  }

  interface Theme {
    background: Background
    spacing: {
      paddings: Spacing
      margins: Spacing
    }
  }

  @Component({
    components: {
      OneCmsGridRender: () => import('~/components/molecules/renders/OneCmsGridRender.vue'),
    },
  })
  export default class OneCmsGridRender extends Vue {
    @Prop({
      type: Object,
      required: false,
    })
    readonly element!: any

    @Prop({
      type: Object,
      required: false,
    })
    readonly availableComponents: any

    get isContainer() {
      return this.element.type === 'container'
    }

    get elementId() {
      return this.element.theme?.settings?.id || ''
    }

    get elementClasses() {
      return this.element.theme && this.element.theme.settings.classes
        ? this.element.theme.settings.classes
        : ''
    }

    get elementStyles() {
      const theme = this.element.theme
      if (!theme) {
        return {}
      }

      return this.getStyles(theme)
    }

    get checkStaticHtmlScript() {
      const tenantScriptsOff = (this.$route.query?.tenantScriptsOff === 'true')
      if (this.element.type === 'staticHtml' && this.element.html.includes('<script>') && tenantScriptsOff) {
        const copyElement = cloneDeep(this.element)
        const scriptRegexp: RegExp = /<script[\s\S]*?>[\s\S]*?<\/script>/gi
        const staticHtml: string = this.element.html
        copyElement.html = staticHtml.replace(scriptRegexp, '')

        return copyElement
      }
      return this.element
    }

    getSpacingStyles(themeStyles: Theme) {
      const { spacing } = themeStyles
      if (!spacing) {
        return {}
      }
      const spacingsStyles = {}
      const validProperties = ['top', 'right', 'left', 'bottom']
      if (spacing.paddings) {
        assignPropertiesWhenValid(spacing.paddings, validProperties, spacingsStyles, 'padding-')
      }
      if (spacing.margins) {
        assignPropertiesWhenValid(spacing.margins, validProperties, spacingsStyles, 'margin-')
      }

      return spacingsStyles
    }

    getBackgroundStyles(themeStyles: Theme) {
      const { background } = themeStyles
      if (!background) {
        return {}
      }
      const backgroundStyles: BackgroundStyles = {}
      if (background.color) {
        backgroundStyles.backgroundColor = background.color
      }

      return backgroundStyles
    }

    getStyles(themeStyles: Theme) {
      if (!themeStyles) {
        return
      }
      const background = this.getBackgroundStyles(themeStyles)
      const spacing = this.getSpacingStyles(themeStyles)
      return { ...background, ...spacing }
    }

    get elementTag() {
      return this.isContainer
        ? this.element.tag || 'div'
        : this.availableComponents[this.element.type] || 'div'
    }
  }
